<template>
    <div class="after_sale_view">
        <Card v-if="detail.applyState == applyStateDef.ed" class="card_item" dis-hover>
            <div>
                <h3 v-if="detail.applyRefundState == applyRefundStateDef.agree">卖家同意退款，售后完成</h3>
                <h3 v-else>卖家拒绝退款，售后完成</h3>
            </div>
            <div class="mt_10">退款金额：{{ detail.refund_price | cny }}</div>
        </Card>
        <Card v-if="detail.applyState == applyStateDef.ing" class="card_item" dis-hover>
            <div><h3>买家申请退款，等待卖家处理</h3></div>
            <div class="mt_10">如卖家未在1天1小时13分04秒内处理，将自动退款给买家。</div>
        </Card>

        <Card class="card_item" title="售后信息" dis-hover>
            <div class="relative flex flex_wrap align_start">
                <div class="form_item">
                    <div class="form_label">售后</div>
                    <div class="form_value">{{ getApplyMode(detail.applyMode) }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">申请人</div>
                    <div class="form_value">{{ detail.userName }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">订单编号</div>
                    <div class="form_value">{{ detail.order_no }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">微信交易单号</div>
                    <div class="form_value">{{ detail.wx_order_no }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">售后编号</div>
                    <div class="form_value">{{ detail.refund_order }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">联系电话</div>
                    <div class="form_value">{{ detail.userTel }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">实付款</div>
                    <div class="form_value">{{ detail.money | cny }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">退款金额</div>
                    <div class="form_value">{{ detail.refund_price | cny }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">下单时间</div>
                    <div class="form_value">{{ detail.ctime }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">申请时间</div>
                    <div class="form_value">{{ detail.applyAt }}</div>
                </div>
                <div class="form_item">
                    <div class="form_label">付款时间</div>
                    <div class="form_value">{{ detail.payAt }}</div>
                </div>
            </div>
            <div class="relative width_100_percent form_item cause">
                <div class="form_label">买家备注</div>
                <div class="form_value">{{ detail.userNote || '无' }}</div>
            </div>
            <div class="relative width_100_percent form_item cause">
                <div class="form_label">退款原因</div>
                <div class="form_value">{{ (applyInfoList.find(info => info.id == detail.applyId) || {}).applyNote || detail.applyId }}</div>
            </div>
            <div class="relative width_100_percent form_item cause">
                <div class="form_label">退款说明</div>
                <div class="form_value">{{ detail.applyNote }}</div>
            </div>
        </Card>

        <Card class="card_item" title="商品信息" dis-hover>
            <Table class="good_table" size="small" stripe :columns="goodColumn" :data="detail.goods">
                <template slot-scope="{ row }" slot="goodMessage">
                    <div class="flex align_center justify_center item">
                        <div>
                            <previewImage :thumWidth="60" :thumHeight="60" :src="row.icon"></previewImage>
                        </div>
                        <div class="flex column align_start justify_center ml_10 good_message">
                            <div class="break_all">{{ row.name }}</div>
                            <div class="break_all">{{ row.data }}</div>
                        </div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="price">
                    {{ row.money | cny }}
                </template>
                <template slot-scope="{ row }" slot="realPay">
                    {{ row.money | cny }}
                </template>
                <template slot="fahuoStatus">
                    {{ getSfcStatus(detail.sfc_state) }}
                </template>
            </Table>
        </Card>

        <Card class="card_item log" title="协商记录" dis-hover>
            <Timeline>
                <TimelineItem v-for="(info, idx) in detail.applyInfoListReverse" :key="'apply_info_item_' + idx" :color="idx == 0 && detail.applyInfoList.length > 1 ? 'green' : '#e0e0e0'">
                    <Icon class="fontsize_24" type="md-radio-button-on" slot="dot"></Icon>
                    <div class="time">{{ info.time }}</div>
                    <h4>{{ info.title }}</h4>
                    <div class="item" v-html="info.content"></div>
                </TimelineItem>
            </Timeline>
        </Card>

        <div class="relative flex align_center justify_center mt_20 btns">
            <template v-if="detail.applyState != applyStateDef.ed">
                <Button type="primary" @click="onHandlerRefund(applyRefundStateDef.agree)">同意退款</Button>
                <Button type="error" @click="onHandlerRefund(applyRefundStateDef.refuse)">拒绝退款</Button>
            </template>
            <Button type="warning" @click="onBack">返回</Button>
        </div>
        <div class="space"></div>

        <Modal class="modal_refuse" :closable="false" v-model="showModal" :title="formData.applyRefundState == applyRefundStateDef.agree ? '同意退款' : '拒绝退款'" width="500" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">售后方式</div>
                {{ getApplyMode(detail.applyMode) }}
            </div>
            <div class="form_item">
                <div class="form_label must_input">退款金额</div>
                <Input type="text" v-model.trim="formData.refundPrice" />
            </div>
            <div class="form_item">
                <div class="form_label align_self_start must_input">退款方式</div>
                原支付渠道返回
            </div>
            <div class="form_item">
                <div class="form_label align_self_start" :class="{ must_input: formData.applyRefundState == applyRefundStateDef.refuse }">{{ formData.applyRefundState == applyRefundStateDef.agree ? '理由说明' : '拒绝原因' }}</div>
                <Input type="textarea" :rows="4" v-model.trim="formData.applyRefundNote" :maxlength="200" show-word-limit></Input>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelModal">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmModal">确定</Button>
            </div>
        </Modal>

        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import previewImage from '@/components/previewImage';
import { isNotEmptyString, isNumberGt0 } from '@/lib/util';
import { reqApplyInfoList, reqCheckRefund, reqOrderView } from '@/lib/request/auth2';
import { applyRefundStateDef, applyStateDef, getApplyMode, getSfcStatus } from '@/lib/order';

export default {
    name: 'afterSaleView',
    components: {
        previewImage,
    },
    data() {
        this.applyStateDef = applyStateDef;
        this.applyRefundStateDef = applyRefundStateDef;
        return {
            isEdit: false,
            id: null,
            detail: {},
            goodColumn: [
                { slot: 'goodMessage', title: '商品信息', width: 300 },
                { slot: 'price', title: '单价', minWidth: 100 },
                { key: 'num', title: '数量', minWidth: 60 },
                { slot: 'realPay', title: '小计', minWidth: 100 },
                { slot: 'fahuoStatus', title: '发货状态', minWidth: 120 },
            ],
            goodList: [],
            showModal: false,
            formData: {
                applyRefundState: null,
                applyRefundNote: null,
                orderNo: null,
                refundPrice: null,
            },
            applyInfoList: [],
        };
    },
    mounted() {
        this.routeIdInject();
        this.getDetail();
        this.getApplyInfoList();
    },
    watch: {
        //
    },
    methods: {
        getSfcStatus,
        getApplyMode,
        getApplyInfoList() {
            reqApplyInfoList({
                pageNums: 1,
                pageSize: 100,
            }).then(res => {
                this.applyInfoList = res?.data?.list || [];
            });
        },
        //获取详情
        getDetail() {
            this.showAjaxLoading();
            reqOrderView({ id: this.id })
                .then(res => {
                    let detail = res?.data?.row || {};
                    detail.applyInfoListReverse = (detail.applyInfoList || []).reverse();
                    this.detail = detail;
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //返回
        onBack() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { title, pic } = params || {};
            let vs = [
                { value: title, tip: '请输入名称' },
                { value: pic, tip: '请选择图片' },
            ];

            hm.validateForm(vs)
                .then(() => {
                    reqSaveBanner(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.goUrl('/bannerManagerList');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        //处理退款
        onHandlerRefund(applyRefundState) {
            this.formData.applyRefundState = applyRefundState;
            this.formData.refundPrice = this.detail.money;
            this.formData.orderNo = this.detail.order_no;
            this.formData.applyRefundNote = null;

            this.showModal = true;
        },
        onCancelModal() {
            this.showModal = false;
            setTimeout(() => {
                this.hideAjaxLoading();
                this.getDetail();
            }, 500);
        },
        onConfirmModal() {
            let { applyRefundState, orderNo, refundPrice, applyRefundNote } = this.formData;
            if (!isNumberGt0(refundPrice)) {
                this.fadeWarning('退款金额必须为为大于0的数字');
                return;
            }
            if (applyRefundState == applyRefundStateDef.refuse) {
                if (!isNotEmptyString(applyRefundNote)) {
                    this.fadeWarning('请选择拒绝原因');
                    return;
                }
            }

            this.showAjaxLoading();
            reqCheckRefund({
                applyRefundState,
                applyRefundNote,
                orderNo,
                refundPrice,
            })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.onCancelModal();
                })
                .catch(res => {
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
<style lang="less" scoped>
.after_sale_view {
    .good_message {
        padding: 10px 0;
        & /deep/ div {
            font-size: 14px;
        }
    }
    .form_item {
        .form_label {
            align-self: flex-start;
        }
        .form_value {
            width: 250px;
            word-break: break-all;
        }
        &.cause {
            .form_value {
                flex: 1;
            }
        }
    }
    & .card_item ~ .card_item {
        margin-top: 20px;
    }
    .good_table {
        & /deep/ span,
        & /deep/ div {
            font-size: 14px !important;
        }
    }
    .card_item {
        &.log {
            & /deep/ .ivu-card-body {
                padding: 40px;
            }
            & /deep/ .ivu-timeline {
                & span,
                & div {
                    font-size: 14px;
                }
                margin-left: 150px;
            }
            .time {
                position: absolute;
                top: 0;
                right: calc(100% + 10px);
                white-space: nowrap;
                font-size: 14px;
            }
            .item {
                margin-top: 5px;
            }
        }
    }
    .btns {
        gap: 30px;
    }
    .space {
        height: 50px;
    }
}

.modal_refuse {
    .form_label {
        width: 120px !important;
    }
}
</style>
